
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { format } from 'date-fns';
import Modal from '../../../components/util/Modal.vue';
import ModalButton from '../../../components/util/ModalButton.vue';

export default defineComponent({
  components: {
    Modal,
    ModalButton,
  },
  setup() {
    const store = useStore();
    const meta = ref<{ last: any; current: any }>();
    const umfragen = ref();

    const start = () => {
      store.dispatch('startFeedback').then((res: any) => {
        store.dispatch('addNotification', { message: 'Feedback gestartet' });
        fetch();
      });
    };

    const stop = () => {
      store.dispatch('stopFeedback').then((res: any) => {
        store.dispatch('addNotification', { message: 'Feedback gestoppt' });
        store.dispatch('setModal', { link: 'stopUmfrage', value: false });
        fetch();
      });
    };

    const fetch = () => {
      store.dispatch('fetchFeedbackMeta').then((res: any) => {
        meta.value = res;
      });

      store.dispatch('API', { url: 'admin/umfragen' }).then((res: any) => {
        umfragen.value = res.data.message.sort((a: any, b: any) => {
          if (a.createdat > b.createdat) return -1;
          if (a.createdat < b.createdat) return 1;
          return 0;
        });
      });
    };

    onMounted(() => {
      if (store.getters.hasPerm('feedback.manage')) {
        fetch();
      }
    });

    return {
      meta,
      start,
      stop,
      umfragen,
      format,
    };
  },
});
