
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { mdiArrowRight, mdiMagnify, mdiDiamond, mdiStar, mdiCrown, mdiClose } from '@mdi/js';

import Modal from '../../../components/util/Modal.vue';
import ModalButton from '../../../components/util/ModalButton.vue';
import Checkbox from '../../../components/util/Checkbox.vue';
import TypeSelect from '../../../components/util/TypeSelect.vue';

interface Skill {
  id: number;
  name: string;
  group: string;
  description: string;
  strategic: Array<number>;
  future: Array<number>;
  leadership: boolean;
}

interface APIResult {
  skill: Skill;
  units: Array<{ id: number; name: string; selected: boolean }>;
}

export default defineComponent({
  components: {
    Modal,
    ModalButton,
    Checkbox,
    TypeSelect,
  },
  setup() {
    const store = useStore();

    const skills = ref<Array<APIResult>>();
    const units = ref<Array<{ id: number; name: string; selected: boolean }>>([]);
    const init = () => {
      store.dispatch('API', { url: `admin/skills` }).then((res: any) => {
        skills.value = res.data.message.sort((a: APIResult, b: APIResult) => {
          if (a.skill.id > b.skill.id) return 1;
          if (a.skill.id < b.skill.id) return -1;
          return 0;
        }) as Array<APIResult>;

        //skills.value = res.data.message.sort((a,b)=> store.getters.getSort(a,b)) as Array<APIResult>;

        //selected.value = JSON.parse(JSON.stringify(skills.value[id]));
      });
      store.dispatch('fetchUnits').then((res: any) => {
        units.value = JSON.parse(JSON.stringify(res));
      });
    };

    const filter = ref('');

    const filteredskills = computed(() => {
      return skills.value?.filter((item) => item.skill.name.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase()));
    });

    const selected = ref<APIResult | undefined>();
    const selectSkill = (id: number) => {
      if (skills.value) {
        selected.value = skills.value.find((item) => item.skill.id == id);

        selected.value?.units.sort((a: any, b: any) => {
          if (a.id > b.id) return 1;
          if (b.id > a.id) return -1;
          return 0;
        });
      }
      return;
    };

    const selectedcnt = computed(() => {
      if (selected.value == undefined) return 0;
      return selected.value.units.filter((unit) => unit.selected).length;
    });

    onMounted(() => {
      init();
    });

    const groupoptions = [
      { label: 'Persönliche Skills', value: 'personal' },
      { label: 'Soziale Skills', value: 'social' },
      { label: 'Fachliche Skills', value: 'working' },
    ];

    const addSkill = () => {
      if (skills.value) {
        const id = -skills.value.length;
        skills.value.unshift({
          skill: {
            id,
            name: 'Neuer Skill',
            group: 'personal',
            description: '',
            strategic: [],
            future: [],
            leadership: false,
          },
          units: units.value.map((item: any) => {
            item.selected = false;
            return item;
          }),
        });

        selectSkill(id);
      }
    };

    const updateSkill = () => {
      if (selected.value) {
        if (selected.value.skill.id < 0) {
          store.dispatch('API', { url: `admin/skills`, method: 'post', data: { ...selected.value } }).then(() => {
            init();
            store.dispatch('addNotification', { message: 'Skill gespeichert' });
            store.dispatch('fetchSkills', { force: true });
          });
        } else {
          store.dispatch('API', { url: `admin/skills/${selected.value.skill.id}`, method: 'patch', data: { ...selected.value } }).then(() => {
            init();
            store.dispatch('addNotification', { message: 'Skill gespeichert' });
            store.dispatch('fetcshSkills', { force: true });
          });
        }
      }
    };

    const removeSkill = (id: number | undefined) => {
      if (id == -1) {
        // TODO: What to do....
      } else {
        store.dispatch('setModal', { link: `delete_skill_${id}`, value: false });
        store.dispatch('API', { url: `admin/skills/${id}`, method: 'delete' }).then(() => {
          selected.value = undefined;
          init();
        });
      }
    };

    const onFocus = () => {
      if (selected.value?.skill.name == 'Neuer Skill') {
        selected.value.skill.name = '';
      }
    };

    return {
      skills,
      filteredskills,
      selected,
      selectSkill,
      groupoptions,
      updateSkill,
      addSkill,
      units,
      removeSkill,
      mdiArrowRight,
      mdiMagnify,
      mdiDiamond,
      mdiStar,
      mdiCrown,
      mdiClose,
      onFocus,
      selectedcnt,
      filter,
    };
  },
});
