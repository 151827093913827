
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { mdiDelete } from '@mdi/js';
import { useRouter } from 'vue-router';

interface Skill {
  id: number;
  name: string;
  group: string;
  description: string;
  strategic: boolean;
}

interface UserData {
  [propName: number]: {
    // SKill ID
    target?: number; // Cached
    value?: number; // Cached
    leader?: number; // Cached
    self?: number; // Cached
    set?: number;
    detailed: {
      [propName: number]: number; // Cached <UserID>: Value
      history: {
        [propName: number]: {
          // Survey ID
          [propName: number]: number; // <UserID>: value
        };
      };
    };
  };
}

export default defineComponent({
  components: {
    // Modal,
    // ModalButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const skills = ref<Array<Skill>>();

    const grader = ref(0);
    const user = ref(0);
    const feedback = ref(0);
    const feedbacks = ref<
      Array<{
        user: any;
        count: number;
      }>
    >([]);
    const umfragen = ref();

    const init = () => {
      store.dispatch('API', { url: `admin/feedback` }).then((res: any) => {
        for (const index in res.data.message) {
          const grade = res.data.message[index];
          feedbacks.value = [...feedbacks.value, grade];
        }
        feedbacks.value = feedbacks.value.sort((a, b) => {
          if (a.count > b.count) return -1;
          if (b.count > a.count) return 1;
          return 0;
        });
      });
    };

    onMounted(() => {
      init();
    });

    const getSkillName = (id: number) => {
      return skills.value?.find((item: any) => item.id == id)?.name;
    };

    return {
      router,
      grader,
      user,
      umfragen,
      skills,
      feedback,
      feedbacks,
      getSkillName,
      mdiDelete,
    };
  },
});
