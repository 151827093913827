
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '../../../components/util/Modal.vue';
import ModalButton from '../../../components/util/ModalButton.vue';
import { mdiContentSave, mdiDelete } from '@mdi/js';
import { format, parse } from 'date-fns';

interface APIResult {
  skill: Skill;
  units: Array<{ id: number; name: string; selected: boolean }>;
}

interface Skill {
  id: number;
  name: string;
  group: string;
  description: string;
  strategic: boolean;
}

interface UserData {
  [propName: number]: {
    // SKill ID
    target?: number; // Cached
    value?: number; // Cached
    leader?: number; // Cached
    self?: number; // Cached
    set?: number;
    detailed: {
      [propName: number]: number; // Cached <UserID>: Value
      history: {
        [propName: number]: {
          // Survey ID
          [propName: number]: number; // <UserID>: value
        };
      };
    };
  };
}

export default defineComponent({
  components: {
    Modal,
    ModalButton,
  },
  setup() {
    const store = useStore();
    const users = ref([]);
    const skills = ref<Array<Skill>>();

    const grader = ref(0);
    const user = ref(0);

    const feedbacks = ref<{ [propName: number]: boolean }>({});
    const umfragen = ref();

    const init = () => {
      store.dispatch('fetchWorkers').then((res: any) => {
        users.value = JSON.parse(
          JSON.stringify(
            res.sort((a: any, b: any) => {
              if (a.id > b.id) return 1;
              if (a.id < b.id) return -1;
              return 0;
            })
          )
        );
      });

      store.dispatch('fetchSkills').then((res: any) => {
        skills.value = res;
      });

      store.dispatch('API', { url: 'admin/umfragen' }).then((res: any) => {
        umfragen.value = res.data.message.sort((a: any, b: any) => {
          if (a.createdat > b.createdat) return -1;
          if (a.createdat < b.createdat) return 1;
          return 0;
        });
      });
    };

    onMounted(() => {
      init();
    });

    const getSkillName = (id: number) => {
      return skills.value?.find((item: any) => item.id == id)?.name;
    };

    const useroptions = computed(() => {
      return users.value?.map((item: any) => {
        return { label: `${item.firstname} ${item.lastname}`, value: item.id };
      });
    });

    // const skilloptions = computed(() => {
    //   return skills.value?.map((item: APIResult) => {
    //     return { label: item.skill.name, value: item.skill.id };
    //   });
    // });

    // const feedbackoptions = computed(() => {
    //   return umfragen.value?.map((item: any) => {
    //     let label = `${format(new Date(item.createdat), 'dd.MM.yyyy kk:mm')}`;
    //     if (item.running) {
    //       label += ' - (Laufend)';
    //     } else {
    //       label += ` - ${format(new Date(item.createdat), 'dd.MM.yyyy kk:mm')}`;
    //     }
    //     return { label, value: item.id };
    //   });
    // });

    const update = () => {
      if (user.value != 0 && grader.value) {
        store.dispatch('API', { url: `admin/feedback/${grader.value}/${user.value}` }).then((res: any) => {
          feedbacks.value = res.data.message;
        });
      }
    };

    watch([user, grader], () => {
      update();
    });

    const removeFeedback = (id: number, grade: boolean) => {
      store
        .dispatch('API', {
          url: 'admin/feedback',
          method: 'patch',
          data: {
            id: user.value,
            skill: id,
            feedbacker: grader.value,
            grade,
          },
        })
        .then(() => {
          store.dispatch('setModal', { link: ` delete_feedback_${id}_${grader.value}_${user.value}`, value: false });
          update();
          init();
        });
    };

    return {
      grader,
      user,
      umfragen,
      skills,

      feedbacks,
      useroptions,
      // skilloptions,
      // feedbackoptions,
      removeFeedback,
      getSkillName,
      mdiDelete,
    };
  },
});
