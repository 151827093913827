
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Modal from '../../../components/util/Modal.vue';
import ModalButton from '../../../components/util/ModalButton.vue';
import AutoComplete from '../../../components/util/AutoComplete.vue';
import CheckBox from '../../../components/util/Checkbox.vue';

import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountCancel, mdiAccountCheck, mdiEmailFast } from '@mdi/js';

interface Unit {
  id: number;
  name: string;
}

interface UserRolesUnit {
  id: number;
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  unit_id: string;
  name: string;
  roleid: number;
  rolename: string;
  data: any;
  state: string;
}

export default defineComponent({
  components: {
    Modal,
    ModalButton,
    AutoComplete,
    CheckBox,
  },
  setup() {
    const store = useStore();
    const users = ref<Array<UserRolesUnit>>([]);
    const selected = ref<any>({});
    const editnew = ref(false);
    const units = ref<Array<Unit>>([]);

    const init = () => {
      store.dispatch('fetchAllWorkers').then((res: any) => {
        users.value = JSON.parse(
          JSON.stringify(
            res.sort((a: any, b: any) => {
              if (a.id > b.id) return 1;
              if (a.id < b.id) return -1;
              return 0;
            })
          )
        );
      });

      store.dispatch('API', { url: 'admin/units' }).then((res: any) => {
        units.value = res.data.message;
      });
    };

    onMounted(() => {
      init();
    });

    const selectUser = (id?: number) => {
      if (id) {
        const user: any = users.value.find((item: any) => {
          return item.id == id;
        });

        if (user) {
          selected.value = {
            id: user.id,
            email: user.email,
            login: user.email,
            vorname: user.firstname,
            nachname: user.lastname,
            unit_id: user.unit_id,
            role: user.rolename,
            state: user.state,
            managers: [],
            leadership: user.data.leadershipskills || false,
          };
          store.dispatch('fetchManagers', id).then((res: any) => {
            console.log(res);
            selected.value.managers = res;
          });
        } else {
          selected.value = undefined;
        }
      } else {
        selected.value = {
          email: '',
          email2: '',
          login: '',
          vorname: '',
          nachname: '',
          unit_id: -1,
          role: 'worker',
          data: {},
          state: undefined,
          managers: [],
          leadership: false,
        };
      }
    };

    const unitoptions = computed(() => {
      return [
        { value: -1, label: 'Keine Abteilung' },
        ...units.value.map((item: any) => {
          return { value: item.id, label: item.name };
        }),
      ];
    });

    const roleoptions = store.getters.getRoleList;

    const saveUser = () => {
      if (selected.value) {
        if (selected.value.id) {
          store
            .dispatch('updateWorker', { id: selected.value.id, user: { ...selected.value, login: selected.value.email } })
            .then((res: any) => {
              store.dispatch('setModal', { link: `admin_edit`, value: false });
              init();
            })
            .catch((err: any) => {
              store.dispatch('addNotification', { message: err.response.data.message, type: 'error', lifetime: 5000 });
            });
        } else {
          if (selected.value.email != selected.value.email2) {
            store.dispatch('addNotification', { type: 'error', message: 'Email-Adressen stimmen nicht überein.' });
            return;
          }

          store
            .dispatch('insertWorker', { user: { ...selected.value, login: selected.value.email } })
            .then((res: any) => {
              store.dispatch('setModal', { link: `admin_edit`, value: false });
              init();
            })
            .catch((err: any) => {
              store.dispatch('addNotification', { message: err.response.data.message, type: 'error', lifetime: 5000 });
            });
        }
      }
    };

    const getUnitName = (id: number) => {
      return units.value.find((item: any) => item.id == id)?.name || '';
    };

    const removeUser = (id: number) => {
      store.dispatch('removeWorker', id).then(() => {
        store.dispatch('setModal', { link: `delete_user_${id}`, value: false });
        init();
      });
    };

    const deleteName = ref('');

    const invite = (id: number) => {
      store
        .dispatch('inviteUser', id)
        .then(() => {
          store.dispatch('addNotification', { message: 'Einladung wurde versendet!' });
          store.dispatch('setModal', { link: `admin_edit`, value: false });
          init();
        })
        .catch((e: any) => {
          console.error(e);
        });
    };

    const managers = computed(() => {
      return store.getters.getWorkers.map((worker: any) => {
        return { value: worker.id, label: `${worker.firstname} ${worker.lastname}` };
      });
    });

    const managers_selected = ref<Array<{ value: number; label: string }>>([]);

    return {
      managers,

      invite,
      deleteName,
      users,
      selectUser,
      selected,
      editnew,
      unitoptions,
      roleoptions,
      saveUser,
      removeUser,
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiAccountCancel,

      mdiAccountCheck,
      mdiEmailFast,
      getUnitName,
      getRoleByValue: store.getters.getRoleByValue,
    };
  },
});
