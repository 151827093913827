import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-283ab391"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row mb-3" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-11" }
const _hoisted_6 = { class: "col-1" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ModalButton = _resolveComponent("ModalButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.units.length <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('app.loading')), 1))
      : _createCommentVNode("", true),
    (_ctx.units.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewUnit()))
              }, _toDisplayString(_ctx.$t('management.createunit')), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.units, (unit, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row align-items-center",
              key: unit.id
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_LabeledInput, {
                  label: _ctx.$t('management.name'),
                  modelValue: _ctx.units[index].name,
                  "onUpdate:modelValue": [($event: any) => ((_ctx.units[index].name) = $event), ($event: any) => (_ctx.nameChanged(unit))]
                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Icon, {
                  width: 35,
                  height: 35,
                  path: _ctx.mdiContentSave,
                  onClick: ($event: any) => (_ctx.saveUnit(unit)),
                  class: _normalizeClass(unit.changed ? 'active hover' : 'disabled')
                }, null, 8, ["path", "onClick", "class"]),
                _createVNode(_component_ModalButton, {
                  link: `delete_unit_${unit.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, {
                      width: 35,
                      height: 35,
                      path: _ctx.mdiDelete,
                      class: "delete hover"
                    }, null, 8, ["path"])
                  ]),
                  _: 2
                }, 1032, ["link"]),
                _createVNode(_component_Modal, {
                  link: `delete_unit_${unit.id}`,
                  title: _ctx.$t('management.deleteunit_title', { unit: unit.name })
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('management.units.users')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('management.units.skills')), 1),
                    _createElementVNode("p", null, [
                      _createElementVNode("button", {
                        onClick: ($event: any) => (_ctx.removeUnit(unit))
                      }, _toDisplayString(_ctx.$t('management.deleteconfirm')), 9, _hoisted_7)
                    ])
                  ]),
                  _: 2
                }, 1032, ["link", "title"])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}