
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '../../../components/util/Modal.vue';
import ModalButton from '../../../components/util/ModalButton.vue';
import { mdiContentSave, mdiDelete } from '@mdi/js';

type Unit = {
  id: number;
  name: string;
  new: boolean;
  changed: boolean;
};

export default defineComponent({
  components: {
    Modal,
    ModalButton,
  },
  setup() {
    const units = ref<Array<Unit>>([]);
    const store = useStore();

    const init = () => {
      store.dispatch('API', { url: 'admin/units' }).then((res: any) => {
        units.value = res.data.message
          .map((item: any) => {
            return {
              ...item,
              new: false,
              changed: false,
            };
          })
          .sort((a: Unit, b: Unit) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
      });
    };

    onMounted(() => {
      init();
    });

    const removeUnit = (unit: Unit) => {
      units.value = units.value.filter((item) => item.id != unit.id);
      store.dispatch('setModal', { link: `delete_unit_${unit.id}`, value: false });
      if (!unit.new) {
        store.dispatch('API', { url: `admin/units/${unit.id}`, method: 'delete' }).then(() => {
          init();
        });
      }
    };

    const saveUnit = (unit: Unit) => {
      if (unit.changed) {
        if (unit.new) {
          store.dispatch('API', { url: 'admin/units', method: 'post', data: { name: unit.name } }).then(() => {
            init();
          });
        } else {
          store.dispatch('API', { url: `admin/units/${unit.id}`, method: 'patch', data: { name: unit.name } }).then(() => {
            init();
          });
        }
      }
    };

    const addNewUnit = () => {
      units.value.unshift({ id: 0 - units.value.length, name: '', changed: true, new: true });
    };

    const nameChanged = (unit: Unit) => {
      unit.changed = true;
    };

    return {
      units,
      removeUnit,
      addNewUnit,
      saveUnit,
      nameChanged,
      mdiContentSave,
      mdiDelete,
    };
  },
});
