
import { defineComponent } from 'vue';
import AdminMenu from '../../../components/admin/AdminMenu.vue';

export default defineComponent({
  components: {
    AdminMenu,
  },
  setup() {
    return {};
  },
});
