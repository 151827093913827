
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import Checkbox from './Checkbox.vue';

export default defineComponent({
  props: {
    units: { type: Object as PropType<Array<{ id: number; name: string; selected: boolean }>> },
    modelValue: Array as PropType<Array<number>>,
  },
  setup(props, { emit }) {
    const selected = ref<Set<number>>(new Set(props.modelValue));

    const filteredunits = computed(() => {
      return props.units?.filter((item) => item.selected);
    });

    watch(filteredunits, () => {
      if (props.units === undefined) return;
      for (const unit of props.units) {
        if (selected.value.has(unit.id) && !unit.selected) selected.value.delete(unit.id);
      }
    });

    const onClick = (id: number) => {
      if (selected.value.has(id)) selected.value.delete(id);
      else selected.value.add(id);

      emit('update:modelValue', [...selected.value]);
    };

    return {
      filteredunits,
      selected,
      onClick,
    };
  },
  components: { Checkbox },
});
