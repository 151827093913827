import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20a641c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "unitgrid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredunits, (unit) => {
      return (_openBlock(), _createBlock(_component_Checkbox, {
        onClick: ($event: any) => (_ctx.onClick(unit.id)),
        modelValue: _ctx.selected.has(unit.id),
        label: unit.name,
        key: `${unit.id}${unit.selected}`
      }, null, 8, ["onClick", "modelValue", "label"]))
    }), 128))
  ]))
}