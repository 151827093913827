import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "adminindex" }
const _hoisted_2 = { class: "row mb-3 mt-1" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headline = _resolveComponent("Headline")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Headline, { h1: "Administration" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AdminMenu)
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}